@import '../../scss/variables.scss';

div.main-wrapper .middle .page-content .main-content {
  background: transparent;
}
.top-winner {
  font-family: 'Lato';
  width: 100%;
  &__header {
    display: grid;
    grid-template-columns: 87% 8%;
    grid-column-gap: 5%;
    padding-bottom: 3px;
    border-bottom: 1px solid  #424647;
  }
  &__header_date-row {
    display: flex;
    h1 {
      width: 30%;
      color: #FFFFFF;
    }
  }
  &__header_date-unput-row {
    //width: 100%;
    //display: flex;
    //justify-content: space-between;
    //flex-wrap: wrap;
    //display: grid;
    //grid-template-columns: 1fr 1fr 1.2fr 1fr;
    //grid-template-columns: repeat(8, 1fr);
    //column-gap: 5px;
    //margin: 5px 0;
    width: 80%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 5px;
    column-gap: 20px;
    margin: 5px 0;
    //&-drop-down-wrapper {
    //  width: 168px;
    //}
    .custom-text-input_black {
      input {
        //min-width: 145px;
        min-width: 110px;
        width: 70%;
      }
    }
  }
  &__header-search-column {
    display: flex;
    align-items: flex-end;
    //justify-content: space-between;
    justify-content: flex-end;
    position: relative;
    padding: 0 5px 7px 5px;

    &-buttons {
      display: flex;
      //width: 50%;
      justify-content: center;
      padding-left: 10px;
      align-items: center;
      &-autoRefresh{
        margin-right: 10px !important;
        height: 20px !important;
        width: 20px !important;
      }
      &-autoRefreshTitle{
        width: 85px;
      }
    }
    &-search-wrapper {
      width: 318px;
    }
  }
  ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  &__table {
    &-row-date {
     display: flex;
      justify-content: space-between;
      margin: 5px 0;
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
    }
    &-header {
     display: flex;
      li {
        width: calc(100% / 5);
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        color: #849398;
      }
      &-sort {
        position: relative;
        cursor: pointer;
      }
      &-sort:after {
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        top: 1px;
        right: -14px;
        background-image: url("../../static/images/pagination/single.svg");
        background-repeat: no-repeat;
        background-size: 8px;
        background-position: center;
        transform: rotate(270deg);
      }
      &-sort.up:after {
        top: 5px;
        transform: rotate(90deg);
      }
    }
    &-body {
      margin-top: 10px;
      ul {
        background-color: #2E1D30;
      }
      ul:nth-child(even) {
        background-color: #170519;
      }
    }
    &-body-row {
      display: flex;
      align-items: center;
      li {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% / 5);
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        position: relative;
      }
      &-date {
        display: flex;
        flex-direction: column;
      }
      &-date div.time {
        color: #849398;
      }
      .payment-reports__edit-button {
        background-image: url("../../static/images/edit.svg");
        background-repeat: no-repeat;
        background-position: right;
        background-size: 20px;
        position: absolute;
        width: 40px;
        height: 100%;
        cursor: pointer;
        top: 0;
        right: 0;
      }
    }
  }
}

@media (max-width: 1400px) {
  div.payment-reports {
    &__header {
      grid-template-columns: 85% 8%;
      grid-column-gap: 7%;
      &_date-unput-row {
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 5px;
      }
    }
  }
}

.red{
  color: white !important;
}
