@import '../../scss/variables.scss';

div.main-wrapper .middle .page-content .main-content {
  background: transparent;
}

.customer-withdraw-reports {
  font-family: 'Lato';
  width: 100%;
  &__header {
    display: grid;
    grid-template-columns: 87% 8%;
    grid-column-gap: 5%;
    padding-bottom: 3px;
    border-bottom: 1px solid  #424647;
  }
  &__header_date-row {
    display: flex;
    h1 {
      width: 300px;
      color: #FFFFFF;
    }
  }
  &__header_date-unput-row {
    width: 100%;
    display: grid;
    //grid-template-columns: 1fr 1fr 1.2fr 1fr 1fr;
    grid-template-columns: repeat(8, 1fr);
    column-gap: 5px;
    margin: 5px 0;
    //&-drop-down-wrapper {
    //  width: 168px;
    //}
    .customer-withdraw-reports__textInputCustom {
        width: 100%;
      min-width: 0;
      input {
        width: 100%;
        min-width: 0;
      }
    }
  }

  &__header-search-column {
    display: flex;
    align-items: end;
    //justify-content: space-between;
    justify-content: flex-end;
    position: relative;
    padding: 0 5px 7px 5px;
    flex-direction: column;

    &-buttons {
      display: flex;
      flex-direction: row ;
      padding-left: 10px;
    }
    &-search-wrapper {
      width: 318px;
    }
  }
  ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  &__table {
    &-row-date {
      display: flex;
      justify-content: flex-end;
      margin: 5px 0;
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
    }
    &-header {
      display: flex;
      li {
        width: calc(100% / 12);
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        color: #849398;
      }
      &-sort {
        position: relative;
        cursor: pointer;
        //span:after {
        //
        //}
      }
      &-sort:after {
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        top: 1px;
        right: -14px;
        background-image: url("../../static/images/pagination/single.svg");
        background-repeat: no-repeat;
        background-size: 8px;
        background-position: center;
        transform: rotate(270deg);
      }

      &-sort.up:after {
        top: 5px;
        transform: rotate(90deg);
      }
    }
    &-body {
      margin-top: 10px;
      ul {
        background-color: #2E1D30;
      }
      ul:nth-child(even) {
        background-color: #170519;
      }
    }
    &-body-row {
      display: flex;
      align-items: center;
      li {
        min-height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% / 12);
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        overflow-wrap: anywhere;
        padding: 5px 0;
      }
      &-date {
        display: flex;
        flex-direction: column;
      }
      &-date div.time {
        color: #849398;
      }
    }
  }
  .pending-withdrawals-reports__table-body-row-cancel {
    cursor: pointer;
  }

  .pending-withdrawals-reports__table-body-row-cancel.Complete,
  .pending-withdrawals-reports__table-body-row-cancel.Batch_Created,
  .pending-withdrawals-reports__table-body-row-cancel.Cancelled {
    opacity: 0;
    pointer-events: none;
    cursor: auto;
  }

  .customer-withdraw-reports__auto-refresh-wrapper {
    width: 120px;
    margin-bottom: 10px;
    font-size: 14px;
    .customer-withdraw-reports__auto-refresh-button-wrapper {
      display: flex;
      align-items: center;
    }
    .customer-withdraw-reports__auto-refresh-button {
      margin-left: 10px;
      width: 75px;
      height: 25px;
      padding: 5px;
      border-radius: 25px;
      background: #B71502;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      cursor: pointer;
      transition: background-color .3s ease 0s;

      .customer-withdraw-reports__auto-refresh-on {
        opacity: 0;
      }
    }
    .customer-withdraw-reports__auto-refresh-button.active {
      background: #10827d;
      .customer-withdraw-reports__auto-refresh-on {
        opacity: 1;
      }
      .customer-withdraw-reports__auto-refresh-off {
        opacity: 0;
      }
    }
    .customer-withdraw-reports__auto-refresh-toggle-icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 25px;
      height: 25px;
      background: #fff;
      border-radius: 50%;
      transition: 0.3s;
    }
    .customer-withdraw-reports__auto-refresh-button.active {
      .customer-withdraw-reports__auto-refresh-toggle-icon {
        transform: translate3d(50px, 0px, 0px);
      }
    }
  }
  .customer-withdraw-reports__table-header.autoRefreshOn {
    .customer-withdraw-reports__table-header-sort-button:not(.customer-withdraw-reports__createdAt-button) {
      pointer-events: none;
      cursor: auto;
      opacity: 0.7;
    }
  }
}

@media (max-width: 1400px) {
  div.customer-withdraw-reports {
    &__header {
      grid-template-columns: 85% 8%;
      grid-column-gap: 7%;
      &_date-unput-row {
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 5px;
      }
    }
  }
}

@media (max-width: 1600px) {
  .customer-withdraw-reports {
    &__header-search-column-search-wrapper {
      width: 218px;
    }
  }
}

