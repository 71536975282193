.modal-accept-lock {
    &__content {
        padding: 21px 40px;
        width: 350px;

        h1 {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 5px;
            text-align: center;
        }
    }

    &__controls {
        margin: 0;
        display: flex;
        justify-content: flex-end;

        .cansel-button {
            margin-right: 15px;
            color: #16C431;
            &:hover {
                color: #119B29;
            }

            &:active, &:focus {
                box-shadow: none;
            }
        }
    }
}
.note-input{
    margin-bottom: 15px;
}
