.page-container {
  display: flex;
  flex-direction: column;
  background-color: #230827;
  padding: 10px 25px;
  box-sizing: border-box;
  height: auto;
}
.text-left {
  text-align: left!important;
  display: flex!important;
  justify-content: flex-start !important;
  gap: 20px !important;
  margin-left: 70px !important;
}