.pagination {
    margin: 0;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    & > div {
        display: flex;
        li .page-link {
            font-size: 10px;
            position: static;
        }
    }
    li {
        border: none;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        .page-link {
            background: inherit !important;
            border: none;
            padding: 0;
            font-size: 17px;
            color: white;
            position: relative;
            bottom: 1px;
        }

        &.active {
            border: 1px solid white;
        }

        &:hover {
            cursor: pointer;
            opacity: 0.6;
        }
    }
    label {
        display: flex;
        flex-wrap: nowrap;
        margin: 0;
        white-space: nowrap;
        font-size: 10px;
        display: flex;
        align-items: center;
        input {
            padding: 1px 2px;
            width: 36px;
            margin: 0;
            text-align: center;
        }
    }
}
