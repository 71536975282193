.dw-support-reports-page {
  margin-top: -20px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    border-bottom: 1px solid;
    &-input-wrapper {
      display: flex;
      align-items: center;
      .custom-text-input_white {
        margin-right: 10px;
        input {
          height: 30px;
        }
      }
    }
  }

  li, ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .dw-support-reports-page__table {
    &-header {
      display: grid;
      width: 100%;
      grid-template-columns: 0.9fr 0.5fr 0.5fr 0.9fr 0.3fr 0.6fr 0.6fr 0.6fr 1.2fr;
      li {
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        color: #849398;
      }
    }

    &-body {
      overflow: auto;
      max-height: calc(100vh - 445px);
      margin-top: 10px;
      ul {
        background-color: #2E1D30;
      }
      ul:nth-child(even) {
        background-color: #170519;
      }
    }
    &-body-row {
      display: grid;
      grid-template-columns: 0.9fr 0.5fr 0.5fr 0.9fr 0.3fr 0.6fr 0.6fr 0.6fr 1.2fr;
      align-items: center;
      position: relative;
      li {
        min-height: 60px;
        display: grid;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        overflow-wrap: anywhere;
        padding: 5px 1px;
      }
      &-date {
        display: flex;
        flex-direction: column;
      }
      &-date div.time {
        color: #849398;
      }
    }



    //th {
    //  background-color: #2e1d30;
    //}
    //.table > thead > tr > th,
    //.table > tbody > tr > td {
    //  padding-left: 20px;
    //}
    //th:nth-child(4),
    //td:nth-child(4),
    //td:nth-child(5) {
    //  text-align: end;
    //}
    //.td_time {
    //  text-align: center;
    //  color: #909090;
    //}
  }
}


.attachment_popup{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 999;
  display: flex;
  justify-content: center;  
  align-items: center;
}

.popup_body{
  max-width: 70%;
  max-height: 70%;
  background: #f1f0f0;
  overflow-y: auto;
  padding: 40px;
  position: relative;
  border-radius: 5px;
  .close{
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  .attached-img{
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  }
  .btn_close{
    padding-top: 2%;
    cursor: pointer;
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: flex-end;
    button{
      background: red;
      border-radius: 10px;
      border: none !important;
      outline: none !important;
      color: #fff !important;
      box-shadow: none !important;
      font-weight: 700;
      cursor: pointer;
      &:hover{
        box-shadow: none !important;
        scale: 1.05;
        background: darkred !important;
      }
    }
  }
}

