.reports-pop-up {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  &__body {
    padding: 40px 25px 40px 40px;
    background: #27082A;
    border-radius: 11px;
    width: 100%;
    height: 260px;
    display: grid;
    grid-template-rows: auto auto auto;
    font-family: Lato;
    font-weight: 500;
    font-size: 13px;
    position: relative;
  }

  &__title {
    font-size: 18px;
    text-align: center;
    padding: 15px 0;
  }
  &__row-title {
    display: flex;
    flex-direction: column;
    div {
      display: flex;
      margin-bottom: 5px ;
      p {
        margin: 0;
        width: 100px;
      }
    }
    &-icon {
      position: absolute;
      right: 23px;
      top: 23px;
      width: 13px;
      height: 13px;
      background-image: url("../../static/images/closeIcon.svg");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &__row-buttons {
    display: flex;

    &-wrapper {
      display: flex;
      align-items: end;
      width: 100%;
      justify-content: end;

      .custom-buttom {
        margin-left: 20px;
      }
    }
  }
}

