
.ip-history {
  .page-header-ip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    h1 {
      display: block !important;
      height: unset;
    }
    button.custom-buttom {
      position: relative;
      top: 2px;
      span {
        position: relative;
        top: -2px;
        left: -1px;
      }
    }
    .ip-history__header-row {
      display: flex;
      .date-time-range__box {
        margin-right: 200px;
      }
    }
  }
  .flag-icon {
    margin-left: 5px;
  }
  &__content {
    overflow: auto;
    max-height: calc(100vh - 260px);
    &-row {
      display: flex;
      justify-content: end;
      .date-time-range__box {
        margin-right: 50px;
      }
    }
    table {
      animation: openAnimation 0.5s ease-in-out;
      thead {
        position: relative;
        z-index: 2;
      }
      thead th {
        position: sticky;
        top: 0;
      }
    }
    @keyframes openAnimation {
      from {
        transform: translateY(-100%) scaleY(0);
      }
      to {
        transform: translateY(0) scaleY(1);
      }
    }

    th {
      background-color: #2e1d30;
    }
    .table > thead > tr > th,
    .table > tbody > tr > td {
      padding-left: 20px;
    }
    th:nth-child(3),
    td:nth-child(3),
    td:nth-child(4) {
      text-align: start;
    }
    .td_time {
      color: #909090;
    }
    .pagination {
      padding: 10px 15px;
    }
  }
}
.fade.button-tooltip.show.tooltip.bs-tooltip-left {
  opacity: 1;
  width: 100%;
  max-width: 23rem;
  .tooltip-inner {
    background: #10827d;
    border-radius: 0;
    color: #fff;
    padding: 7px 15px;
    box-shadow: 0 1px 5px #727272;
    width: 100%;
    height: 10rem;
    color: black;
    text-align: left;
    max-width: 23rem;
  }
  .arrow::before {
    // box-shadow: 0 1px 5px #727272;
    border-left-color: #10827d !important;
  }
}
