.breackdown-statement {
    padding-top: 13px;
    padding-bottom: 14px;

    &__header {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        &>*:not(:last-child) {
            margin-right: 8px;
        }

        .custom-text-input_white,
        button:last-child {
            margin-bottom: 8px;
        }
    }

    &__title {
        display: flex;
        align-items: center;

        h1 {
            margin: 0;
        }
    }

    &__buttons {
        .custom-buttom:first-child {
            margin-right: 5px;
        }
    }

    &__main {
        .nav {
            margin-bottom: 10px;
        }

        .content .breackdown-statement-table {
            text-align: right;

            .text-align-left {
                text-align: left;
            }

            th {
                font-weight: bold;
                vertical-align: bottom;
            }

            td {
                font-weight: normal;
                vertical-align: middle;
            }
        }

        .content .user-roundDetails-table {
            text-align: left;

            .text-align-left {
                text-align: left;
            }

            th {
                font-weight: bold;
                vertical-align: bottom;
            }

            td {
                font-weight: normal;
                vertical-align: middle;
            }
        }


        .market-total {
            margin: 59px 154px 0 auto;

            tr {
                td {
                    padding: 3px 9px 4px 10px;

                    &:first-child {
                        text-align: right;
                    }
                }
            }

            &__sum {
                border-top: 1px solid #fff;
                border-bottom: 1px solid #fff;
            }
        }

        .content__results-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 45px;

            .round-results {
                display: flex;
                align-items: center;
                flex-direction: column;
                margin: 30px 4px;
                min-width: 35%;
                background-color: #264E47;
                border-radius: 5px;
                color: #FFFFFF;
                font-size: 12px;
            }

            .round-results_header {
                width: 95%;
                margin: 14px 0 15px;
                color: #FFFFFF;
                font-size: 12px;

                .round-results_header-info {
                    text-align: center;
                    border-bottom: 1px solid #FFFFFF;
                    padding-bottom: 10px;

                    &-text {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 12px;

                    }
                }
            }

            .round-results_body {
                width: 95%;
                padding: 15px 0;
            }

            .market-total {
                margin: unset;
            }
        }
    }

    p {
        margin: 0;
    }

    .total {
        display: flex;
        width: 100%;
        justify-content: flex-end;

        .total__block {
            margin-top: 15px;
            border-bottom: 1px solid #fff;

            .total__value {
                margin-left: 30px;
            }
        }
    }
}

body .page-header.breackdown-statement__header {
    padding-bottom: 0;
}