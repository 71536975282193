.bet-ticker {
  // max-height: 100%;
  // overflow-y: auto;
  padding-top: 5px;
  .page-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 1px;
    margin-bottom: 10px;

    h1 {
      margin: 2px 22px 5px 0;
    }

    &__main,
    .page-header__filters,
    .page-header__filters > div {
      display: flex;
      flex-wrap: wrap;
    }

    .page-header__filters {
      // height: 43px;
      & > div > * {
        margin-left: 8px;
        margin-top: 5px;
      }

      button.dropdown-toggle.btn.btn-primary {
        height: 37px;
        background-color: #37133f;
        padding-left: 10px;

        &::after {
          top: 18px;
        }
      }
      .custom-text-input_black input {
        height: 37px;
        width: 168px;
        padding-left: 9px;
      }
      .filters__control-buttons {
        align-items: flex-end;
        height: 32px;
        margin-top: 6px;
        & > button {
          padding-right: 13px;
          &:nth-child(2) {
            margin-left: 2px;
            padding-left: 14px;
          }
          & > span {
            position: relative;
            top: -2px;
            left: -1px;
          }
        }
      }
    }
    .refresh {
      padding-top: 9px;
      display: flex;
      &__timer {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 4px;
        left: -3px;
        width: 20px;
        height: 20px;
        margin: 0 5px 0 20px;
        color: rgba(202, 224, 232, 0.8);
      }
      &__button {
        width: 6.62rem;
      }
    }
  }

  &__main {
    max-height: calc(100vh - 100px);
    overflow: auto;
    padding: 20px;
    background-color: #100311;
    table {
      td,
      th {
        padding: 5px 8px !important;
        min-height: 31px !important;
      }
      td {
        white-space: nowrap;
        &:not(:nth-child(3)) {
          max-width: 120px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &:nth-child(4) {
          max-width: 220px;
        }
      }
    }
    .text-align-center {
      text-align: center;
    }
  }
}
