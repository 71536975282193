.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #3a0c3f;

    .login-block {
        background-color: #230826;
        width: 400px;
        padding: 65px 50px 50px 50px;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: 0;
            // background-image: url("../../static/images/logo.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 197px;
            height: 50px;
            transform: translate(-50%, 40%);
        }

        & > div,
        & > button,
        & > p {
            margin: 15px 0 0 0;
        }

        .custom-text-input_white {
            position: relative;
            width: 100%;
            input {
                margin: 0;
                margin-top: 25px;
                height: 42px;
                width: 100%;
                padding: 8px 6px;
                font-size: 16px;
            }
            label {
                left: 0;
                font-size: 14px;
                line-height: 20px;
                font-weight: normal;
            }
        }

        .login__button {
            height: 42px;
            width: 100%;
        }

        p {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: white;
            letter-spacing: 0;
        }

        .login__icons {
            height: 40px;
            display: flex;
            justify-content: center;
            div {
                display: inline-block;
                margin: 0 5px;

                &.firefox-icon {
                    background-image: url("../../static/images/firefox.png");
                    background-size: 35px;
                    width: 35px;
                    height: 33px;
                }

                &.ie-icon {
                    background-image: url("../../static/images/internet-explorer.png");
                    background-size: 41px;
                    width: 38px;
                    height: 38px;
                    position: relative;
                    top: -4px;
                    left: -3px;
                }

                &.chrome-icon {
                    background-image: url("../../static/images/google-chrome.png");
                    background-size: 32px;
                    width: 32px;
                    height: 32px;
                }
            }
        }

        .login__message {
            color: #b71601;
            text-align: left;
        }
    }
}
