.children-listing {
  // padding-top: 9px;
  // padding-bottom: 14px;
  height: 100%;
  &__header {
    display: block;
    height: 46px;
    margin-bottom: 17px;
    & > *:not(:last-child) {
      margin-right: 20px;
    }

    .custom-text-input_white,
    button:last-child {
      margin-bottom: 8px;
    }
  }

  &__title,
  &__search,
  &__status-filter{
    float: left;
  }

  &__buttons {
    float: right;
  }

  &__title {
    width: 274px;
    h1 {
      margin: 0;
      padding-bottom: 5px;
    }
  }

  &__search {
    display: flex;
    flex-wrap: wrap;
    p {
      display: flex;
      align-items: center;
      margin: 0;
      white-space: nowrap;
      margin-right: 10px;
      font-size: 1rem;
      padding-bottom: 2px;
    }
    input {
      margin-right: 10px;
      height: 32px;
      color: black;
    }
  }
  &__status-filter {
    width: 120px;
    height: 32px;
    font-weight: 400;
    font-size: 14px;
    color: #999999;
    &-selected {
      background: #FFFFFF;
      border: 2px solid #DDDDDD;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 11px;
      position: relative;
      cursor: pointer;
    }
    &-selected:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      background-image: url("../../static/images/arrow.svg");
      background-repeat: no-repeat;
      background-position: center;
      width: 30px;
      height: 30px;
      transition: all 0.2s;
    }
    &-selected.active:after {
      transform: rotateX(180deg);
    }
    &-options {
      width: 120px;
      //height: 0px;
      position: relative;
      margin-top: 14px;
      transition: all 200ms;
      z-index: 1;
      opacity: 0;
      li {
        height: 0px;
        list-style-type: none;
        background: #FFFFFF;
        cursor: pointer;
        width: 120px;
        position: relative;
        right: 40px;
        scale: 0;
        //transition: all 300ms;
        color: transparent;
      }
    }
    &-options:before {
      content: "";
      position: absolute;
      top: -11px;
      left: 15%;
      border-style: solid;
      border-width: 0 11px 11px 11px;
      border-color: #ffffff transparent;

      width: 0px;
      //transition: all 2s;
    }
    &-options.active {
      opacity: 1;
      li {
        padding: 10px 15px;
        height: 38px;
        scale: 1;
        color: #5F686B;
      }
      li:hover {
        background: #DDDDDD;;
        color: #5F686B;
      }
      &:before {
        opacity: 1;
      }
    }
  }

  &__buttons {
    padding-top: 5px;
    .custom-buttom:first-child {
      margin-right: 5px;
    }
    span {
      position: relative;
      top: -2px;
    }
  }
  hr {
    background: #cae0e8;
    margin: 8px 0 18px;
  }

  &__main {
    height: 100%;
    .nav {
      margin-bottom: 0.85rem;
    }

    .content {
      // height: 100%;
      display: grid;
      grid-template-columns: auto 2.75rem;

      .table-conteiner {
        overflow-y: scroll;
        max-height: calc(100vh - 345px);

        .disabled {
          opacity: 0.4;
          cursor: auto !important;
        }

        .children-listing-table {
          text-align: right;
          background-color: #170519;

          .text-align-left {
            text-align: left;
          }
          .text-align-center {
            text-align: center;
          }

          &__betting-status {
            &:hover {
              cursor: pointer;
            }
          }

          .show-part-of-table {
            display: none;
          }
          th,
          td {
            height: 4rem;
          }
        }

        th {
          font-weight: bold;
          vertical-align: bottom;
        }
        td {
          font-weight: normal;
          vertical-align: middle;

          color: #ffffff;
        }

        thead th {
          vertical-align: bottom;
        }
      }
    }
  }

  &__toggle-table-show {
    opacity: 0.6;
    background-color: #8b828c;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0 60px 0;
    justify-content: space-between;
    height: calc(100vh - 340px); //added mb delete

    .mdi-icon.show-all-table {
      transform: rotate(180deg);
    }

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
}
body .page-header.children-listing__header {
  padding-bottom: 0;
}
.action {
  text-align: center;
}
.children-listing-table__credit-limit-actions {
  justify-content: center;
  display: flex;
  &-item {
    width: 34px;
    height: 32px;
    background: #10827D;
    box-shadow: inset -2px -2px 0px #0A5551;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 22px;
    cursor:pointer;
    a {
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
    }
  }
  
  &-item:last-child {
    margin-right: 0px;
  }
}
