.transfer-statement-page {
  padding-top: 7px;
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    button.custom-buttom {
      position: relative;
      top: 2px;
      span {
        position: relative;
        top: -2px;
        left: -1px;
      }
    }
  }
  &__content {
    overflow: auto;
    max-height: calc(100vh - 310px);
    th {
      background-color: #2e1d30;
    }
    .table > thead > tr > th,
    .table > tbody > tr > td {
      padding-left: 20px;
    }
    th:nth-child(3),
    td:nth-child(3),
    td:nth-child(4) {
      text-align: end;
    }
    .td_time {
      text-align: center;
      color: #909090;
    }
  }
}
.fade.button-tooltip.show.tooltip.bs-tooltip-left {
  opacity: 1;
  width: 100%;
  max-width: 23rem;
  .tooltip-inner {
    background: #10827d;
    border-radius: 0;
    color: #fff;
    padding: 7px 15px;
    box-shadow: 0 1px 5px #727272;
    width: 100%;
    height: 10rem;
    color: black;
    text-align: left;
    max-width: 23rem;
  }
  .arrow::before {
    // box-shadow: 0 1px 5px #727272;
    border-left-color: #10827d !important;
  }
}

.headcol {
  position: absolute;
  width: 5em;
  left: 0;
  top: auto;
  border-top-width: 1px;
  /*only relevant for first row*/
  margin-top: -1px;
  /*compensate for top border*/
  padding: 0;
}

.headcol button {
  // color: white;
  background-color: #2e1d30;
  border: none;
  border-radius: 0;
}

.red {
  color: red;
}

.green {
  color: green;
}

.orange {
  color: orange;
}


.table{
  margin-left: 70px;
}