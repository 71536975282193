.modal-form {
    &__content {
        padding: 25px 20px 20px;
        width: 500px;

        h1 {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 20px;
            margin-bottom: 30px;
        }

        .custom-text-input_white {
            margin: 25px 0;

            input {
                line-height: initial;
                margin: 25px 0 0;
            }
        }
    }

    &__controls {
        margin: 30px 0 0;
        display: flex;
        justify-content: flex-end;

        .cansel-button {
            margin-right: 15px;
            color: #16C431;
            &:hover {
                color: #119B29;
            }

            &:active, &:focus {
                box-shadow: none;
            }
        }
    }
}