.activity-report {
    &__controls {
        background-color: #0A020B;
        display: flex;
        margin-bottom: 20px;
        .radio-item {
            margin-right: 20px;
        }
        label {
            margin: 0;
        }

    }
    .table {
        text-align: end;
        tr th, tr th:hover {
            background-color: #0E0310;
            font-weight: 400;

        }
    }
    .notes-body{
        margin: 20px 0;
        display: flex;
    }
    .notes-body-card{
        width:55%;
        margin:0 10px 0 0;
        display:flex;
        flex-direction:column;
    }
    .notes_card{
        margin:10px;
        padding:10px 15px;
        border-radius:10px;
        background-color: #27082a;
    }
    .notes-heading{
        padding: 0;
        font-size: 14px;
    }
    .notes-details{
        padding: 0; font-style:italic;
        font-size: 12px;
    }
    .custom-buttom.purple:disabled{
        background-color: #e7e7e7;
        color: #000;
        cursor: not-allowed;
    }
}
